
export default {
  methods: {
    mousemove(e) {
      let x = e.offsetX
      let y = e.offsetY
      let s = e.target.offsetWidth / 1.5
      e.target.style.setProperty('--x', x + 'px')
      e.target.style.setProperty('--y', y + 'px')
      e.target.style.setProperty('--s', s + 'px')
    },
    showAndscrollIntoView() {
      const hash = this.$el.children[0].hash
      if (hash.length > 0) {
        const e = document.getElementById(hash.replace('#', ''))
        if (e) {
          e.classList.remove('d-none')
          e.scrollIntoView()
        }
      }
    }
  },
  props: ['text', 'type']
}
