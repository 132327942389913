
import AsButton from '~/components/AsButton.vue'
export default {
  data() {
    return {
      show: false
    }
  },
  components: {
    AsButton
  },
  methods: {
    getAsStatus() {
      this.show = !this.$store.state.as_closed
    },
    hideAsBanner() {
      as_banner.classList.add('hide')
      setTimeout(() => {
        this.$store.commit('setAsClosed')
        this.show = false
      }, 5000)
    }
  },
  mounted() {
    this.getAsStatus()
  }
}
